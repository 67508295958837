<template>
  <v-row dense>
    <v-col cols="auto" style="width: 110px">
      <v-select
        :value="selected"
        item-value="id"
        item-text="country"
        :menu-props="menuProps"
        :items="selectItems"
        :loading="loading"
        :readonly="readonly"
        return-object
        :cache-items="true"
        @input="updateValue('code', $event)"
      >
        <template v-slot:selection="data">
          <v-avatar left tile size="25">
            <base-icon :name="data.item.iso"></base-icon>
          </v-avatar>
          <span class="ml-2">+{{ data.item.code }}</span>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item" />
          </template>
          <template v-else>
            <v-list-item-avatar tile>
              <base-icon :name="data.item.iso"></base-icon>
            </v-list-item-avatar>

            <v-list-item-content style="flex-wrap: nowrap; width: 220px">
              <v-list-item-title
                class="d-flex flex-row overflow-visible"
                v-html="data.item.country"
              />

              <v-list-item-subtitle
                class="justify-end d-flex flex-row"
                v-html="`+${data.item.code}`"
              />
            </v-list-item-content>
          </template>
        </template>
      </v-select>
    </v-col>
    <v-col>
      <v-text-field
        v-mask="mask"
        :value="trimPhone"
        :hint="mask"
        :label="label"
        :counter="counter"
        :readonly="readonly"
        :error-messages="error"
        @input="updateValue('phone', $event)"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';
import { normalize, denormalize } from 'normalizr';

import { maxLength, notEmoji } from '@/rules';
import BaseIcon from './components/BaseIcon.vue';
import { countryCodeIsoSchema } from './common/schemas';
import { DEFAULT_COUNTRIES_ISO } from './common/constants';
import { default as country_codes } from './common/country_codes.json';

export default {
  name: 'PhoneInput',

  components: {
    BaseIcon,
  },

  directives: {
    mask,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Error,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [maxLength(255), notEmoji],
    },
    counter: {
      type: Number,
      default: 12,
    },
    mask: {
      type: String,
      default: '############',
    },
    menuProps: {
      type: Object,
      default: () => ({ auto: true, overflowY: true }),
    },
  },

  data: () => {
    return {
      code: null,
      countryCodesIds: [],
      countryCodesEntities: {},
    };
  },

  computed: {
    selectItems() {
      let countryCodes = [
        ...this.defaultCountryCodes,
        { divider: true },
        ...this.anotherCountryCodes,
      ];

      countryCodes.forEach((o, i) => (o.id = i + 1));

      return countryCodes;
    },

    defaultCountryCodes() {
      return denormalize(DEFAULT_COUNTRIES_ISO, [countryCodeIsoSchema], {
        [countryCodeIsoSchema.key]: this.countryCodesEntities,
      });
    },

    anotherCountryCodes() {
      const codes = this.countryCodesIds.filter(
        (iso) => !DEFAULT_COUNTRIES_ISO.includes(iso)
      );

      return denormalize(codes, [countryCodeIsoSchema], {
        [countryCodeIsoSchema.key]: this.countryCodesEntities,
      }).sort((a, b) => a.country.localeCompare(b.country));
    },

    trimCode() {
      if (!this.value) return 41;

      return parseInt(this.value.split('+').pop().split(' ').shift(), 10);
    },

    trimPhone() {
      return this.value ? this.value.split(' ').pop() : '';
    },

    selected() {
      return (
        this.code ||
        this.selectItems.find((el) => {
          return el.code === this.trimCode;
        })
      );
    },
  },

  created() {
    this.getCountryCodesIso();
  },

  methods: {
    getCountryCodesIso() {
      const { entities, result } = normalize(country_codes, [
        countryCodeIsoSchema,
      ]);

      this.countryCodesIds = result;
      this.countryCodesEntities = entities[countryCodeIsoSchema.key];
    },

    updateValue(field, value) {
      let phoneWithCode = null;

      if (field === 'code' && value) {
        this.code = value;
      }

      if (field === 'phone' && value) {
        phoneWithCode = `+${this.selected.code} ${value}`;
      }

      if (field === 'code' && value && this.trimPhone) {
        phoneWithCode = `+${value.code} ${this.trimPhone}`;
      }

      if (phoneWithCode !== this.value) {
        this.$emit('input', phoneWithCode);
      }
    },
  },
};
</script>
