<template>
  <img v-if="validateName" :src="iconComponent" :alt="name" />
</template>

<script>
import { getIcons } from '../common/utils';

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
  },

  data: () => {
    return {
      icons: getIcons(),
    };
  },

  computed: {
    iconComponent() {
      return this.icons[this.name];
    },
    validateName() {
      return Object.prototype.hasOwnProperty.call(this.icons, this.name);
    },
  },
};
</script>
