export const getIcons = function getIcons() {
  const icons = {};
  const requireComponents = require.context('../assets/flags/', false, /.svg$/);

  requireComponents.keys().forEach((fileName) => {
    const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
    const componentConfig = requireComponents(fileName);
    icons[iconName] = componentConfig.default || componentConfig;
  });

  return icons;
};
